/*Custom Fonts*/
@font-face {
    font-family: 'nexa-bold';
    src: url('../fonts/nexa-bold-webfont.woff') format('woff2'),
         url('../fonts/nexa-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'nexa-light';
    src: url('../fonts/nexa-light-webfont.woff2') format('woff2'),
         url('../fonts/nexa-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'nexaregular';
    src: url('../fonts/nexa-regular-webfont.woff2') format('woff2'),
         url('../fonts/nexa-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/*Color Variables*/
$green-color: #51B748;
$black-color: #000000;
$blue-color: #00294B;
$gray-color: #6C6C6C;
$dark-gray-color: #5a5a5b;
$white-color: #ffffff;
$offwhite-color: #c9c9c9;
$gray-border-color: #eeeeee;
$menuHeaderBg-color: #001526;


*{
    padding: 0;
    margin: 0;
}
a{
    color: $black-color !important;
    text-decoration: none !important;
}
ul,li{
    margin: 0px;
    padding: 0px !important;
    list-style: none;
}
.text-link{
    color: $green-color !important;
}
.border-top{
    border-top: 1px solid $gray-border-color !important;
}
.gray-bg{
    background-color: #00294b0d;
}
.page-padding-big{
    padding: 140px 0px;
    @media only screen and (max-width: 767px){
        padding: 40px 0px;
    }
}
.page-padding{
    padding: 90px 0px;
    @media only screen and (max-width: 767px){
        padding: 40px 0px;
    }
}
.theme-title{
    font-family: 'nexa-bold';
    font-size: 40px;
    margin-bottom: 20px;
    color: $black-color;
}
.theme-description{
    font-size: 16px;
    line-height: 1.7em;
    font-weight: 400;
    margin-bottom: 10px;
    color: $black-color;
}
td.coin-box {
    padding-left: 50px;
    position: relative;
}
td.value-box {
    text-align: right;
}
.table td, .table th {
    border-top: 0;
    vertical-align: middle;
}
td.value-box span {
    display: block;
    font-size: 15px;
}
.page-title{
    span{
        display: inline-block;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        color: #979797;
        letter-spacing: 0.9px;
        position: relative;
        margin-bottom: 18px;
        &::after{
            position: absolute;
            content: "";
            height: 2px;
            width: 30px;
            background-color: $green-color;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
img {
    max-width: 100%;
}
body .container {
    max-width: 1260px;
}
html, body{
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}
body{
    padding: 0;
    margin: 0 auto;
    font-family: 'nexaregular' !important;
    font-size: 16px;
    .top-header{
        background-color: #e6e6e6;
        padding: 7px 0px;
        .left-topbar{
            @media only screen and (max-width: 767px){
                text-align: center;
                margin-bottom: 10px;
            }
            @media only screen and (max-width: 575px){
                margin-bottom: 0;
            }
            ul{
                @media only screen and (max-width: 575px){
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                li{
                    display: inline-block;
                    margin-right: 30px;
                    font-weight: 400;
                    color: $black-color;
                    font-size: 14px;
                    svg{
                        color: $blue-color;
                        margin-right: 8px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .email-div{
                @media only screen and (max-width: 581px){
                    display: none;
                }
            }
            .mobile-social-icons{
                li{
                    margin-right: 5px;
                }
                @media only screen and (min-width: 581px){
                    display: none;
                }
            }
        }
        .right-topbar{
            float: right;
            @media only screen and (max-width: 767px){
                float: none;
                text-align: center;
            }
            @media only screen and (max-width: 575px){
                display: none;
            }
            ul{
                li{
                    display: inline-block;
                    margin-left: 20px;
                    a{
                        color: $blue-color;
                        svg{
                            transition: 0.7s all;
                            cursor: pointer;
                        }
                    }
                }
            }
        } 
    }
    .menu-header{
        background: $menuHeaderBg-color;
        padding: 25px;
        @media only screen and (max-width: 991px){
            padding: 10px 0;
        }
        .navbar-toggler{
            @media only screen and (max-width: 991px){
                padding-right: 0;
            }
        }
        .navbar-toggler-icon{
            background-image: url(../images/menu-icon.png) !important;
            width: 55px;
            height: 55px;
        }
        .navbar-collapse{
            @media only screen and (max-width: 991px){
                position: fixed;
                top: 0;
                z-index: 9;
                background: $blue-color;
                left: -100%;
                width: 100%;
                bottom: 0;
                padding: 30px 0;
                overflow: auto;
                transition: left 0.5s ease-in-out; // Smooth slide effect
                &.show{
                    left: 0 !important;
                    transition: left 0.5s ease-in-out; // Smooth slide effect
                 
                }
            }
            .close-menubar{
                position: absolute;
                right: 10px;
                top: 10px;
                @media only screen and (min-width: 991px){
                    display: none;
                }
                svg{
                    color: #fff;
                    font-size: 30px;
                }
            }
        }
        .nav-link{
            color: $white-color !important;
            padding: 0;
            cursor: pointer;
            @media only screen and (max-width: 1200px){
                font-size: 12px;
            }
            @media only screen and (max-width: 991px){
                margin-bottom: 15px;
                border-bottom: 1px solid #3d3d3d;
                padding-bottom: 15px;
                padding-left: 15px;
                font-size: 16px;
            }
        }
        .navbar-toggler{
            &:focus{
                box-shadow: none;
            }
        }
        .join-now-btn{
         background: $green-color;
         color: $white-color;
         border-radius: 8px;
         width: 130px;
         height: 50px;
         margin-left: 15px;
         @media only screen and (max-width: 1200px){
            width: 90px;
            font-size: 12px;
         }
         @media only screen and (max-width: 991px){
            width: 130px;
            font-size: 16px;
         }
        }
        &.fixed-header{
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 99;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Add shadow for distinction */
            animation: slideDown 0.50s ease-out;
        }
        @keyframes slideDown {
            from {
              transform: translateY(-100%);
            }
            to {
              transform: translateY(0);
            }
          }
          .dropdown-item{
            &:active, &:hover{
                background-color: #ccc;
            }
          }
    }
    .breadcrumb-area{
        background-image: url(../images/breadcrumb.jpg);
        background-position: center;
        background-size: cover;
        padding: 50px 0px;
        position: relative;
        z-index: 1;
        &::after{
            content: "";
            position: absolute;
            background-color: $blue-color;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            opacity: .82;
            z-index: -1;
        }
        .breadcrumb-title{
            @media only screen and (max-width: 767px){
                text-align: center;
            }
            .heading{
                float: left;
                @media only screen and (max-width: 767px){
                    float: none;
                }
                .theme-title{
                    color: $white-color;
                    margin-bottom: 10px;
                    font-size: 30px;
                    @media only screen and (max-width: 767px){
                        font-size: 20px;
                    }
                }
            }
            .breadcumb-link{
                float: right;
                @media only screen and (max-width: 767px){
                    float: none;
                }
                ul{
                    li{
                        font-size: 18px;
                        display: inline-block;
                        margin-right: 18px;
                        color: $white-color;
                        @media only screen and (max-width: 767px){
                            margin-right: 5px;
                        }
                        .slash-breacumb{
                            padding-left: 15px;
                            @media only screen and (max-width: 767px){
                                padding-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .banner{
        background-image: url(../images/banner-bg.jpg);
        background-size: cover;
        background-position: center;
        position: relative;
        @media only screen and (max-width: 767px){
            padding-top: 40px;
        }
        .slider-main-box{
            display: table;
            width: 100%;
            height: 660px;
            position: relative;
            z-index: 1;
            padding-bottom: 100px;
            @media only screen and (max-width: 991px){
                padding-bottom: 200px;
            }
            @media only screen and (max-width: 767px){
                display: block;
                width: 100%;
                height: auto;
                padding-bottom: 60px;
            }
            .slider-inner{
                display: table-cell;
                vertical-align: middle;
                @media only screen and (max-width: 767px){
                    display: block;
                    width: 100%;
                }
                .contain-box{
                    max-width: 620px;
                    @media only screen and (max-width: 479px){
                        max-width: 100%;
                        text-align: center;
                    }
                    .theme-title{
                        font-size: 65px;
                        color: $white-color;
                        font-family: 'nexa-bold';
                        margin-bottom: 20px;
                        @media only screen and (max-width: 991px) and (min-width: 575px){
                            font-size: 50px;
                        }
                        @media only screen and (max-width: 479px){
                            font-size: 42px;
                        }
                        .text-link{
                            color: $green-color;
                            display: inline-block;
                        }
                    }
                    .theme-description{
                        color: $white-color;
                        font-size: 16px;
                        margin-bottom: 10px;
                        line-height: 1.7em;
                    }
                    .btn-box{
                        margin-top: 50px;
                        .discover-btn{
                            text-transform: uppercase;
                            font-size: 18px;
                            letter-spacing: 0.3px;
                            padding: 14px 20px;
                            border-radius: 8px;
                            background-color: $green-color;
                            color: $white-color;
                            outline: none;
                            border: none;
                        }
                    }
                }
            }
            .slider-bottom{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 40px;
                @media only screen and (max-width: 767px){
                    position: static;
                    display: inline-block;
                    width: 100%;
                    margin-top: 30px;
                }
                .inner-main{
                    background: rgba(255, 255, 255, 0.1);
                    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
                    backdrop-filter: blur(8px);
                    -webkit-backdrop-filter: blur(8px);
                    border: 1px solid rgba(255, 255, 255, 0.18);
                    border-radius: 10px;
                    padding: 20px;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 30px;
                    @media only screen and (max-width: 767px){
                        margin-bottom: 15px;
                    }
                    .img-box{
                        position: absolute;
                        left: -12px;
                        top: -10px;
                        background: rgb(255 255 255 / 3%);
                        box-shadow: 0 8px 32px 0 rgb(31 38 135 / 40%);
                        backdrop-filter: blur(8px);
                        -webkit-backdrop-filter: blur(8px);
                        border: 1px solid rgba(255, 255, 255, 0.18);
                        padding: 20px;
                        border-radius: 50px;
                    }
                    .content-box{
                        padding-left: 80px;
                        .title{
                            font-size: 16px;
                            margin-bottom: 0px;
                            color: $white-color;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(2, 0, 36, 1) 8%, rgb(0 41 75 / 0%) 104%);
            top: 0;
            left: 0;
        }
    }
    .about-area{
        &.page-padding-big{
            @media only screen and (max-width: 991px){
                padding-bottom: 60px;
            }
            @media only screen and (max-width: 767px){
                padding-bottom: 0;
            }
        }
        .left-about{
            position: relative;
            padding: 10px 0px;
            text-align: center;
            @media only screen and (max-width: 991px){
                margin-bottom: 40px;
            }
            img{
                border-radius: 20px;
            }
            .shape-top{
                position: absolute;
                left: -19px;
                top: -36px;
                z-index: -1;
                @media only screen and (max-width: 991px){
                    display: none;
                }
            }
            .shape-bottom{
                position: absolute;
                right: -19px;
                bottom: -35px;
                z-index: -1;
                @media only screen and (max-width: 991px){
                    display: none;
                }
            }
        }
        .right-about{
            padding-left: 30px;
            @media only screen and (max-width: 991px){
                padding-left: 0;
            }
            .page-title{
                .theme-title{
                    font-size: 40px;
                    text-transform: capitalize;
                    @media only screen and (max-width: 479px){
                        font-size: 30px;
                    }
                }
                .theme-description{
                    margin-bottom: 0;
                    color: $gray-color;
                }
            }
            .data-box{
                .inner-about{
                    padding: 20px;
                    text-align: center;
                    border: 1px solid #eee;
                    border-radius: 10px;
                    margin-bottom: 30px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    @media only screen and (max-width: 575px){
                        justify-content: flex-start;
                    }
                    .img-box{
                        @media only screen and (max-width: 575px){
                            margin-right: 15px;
                        }
                    }
                    .theme-description{
                        margin-top: 0px;
                        margin-bottom: 0;
                    }

                }
            }
        }
    }
    .fast-execution-area{
        position: relative;
        .graph-img{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            opacity: 0.40;
        }
        .right-about{
            padding-left: 30px;
            @media only screen and (max-width: 991px){
                padding-left: 0;
            }
            .page-title{
                .theme-title{
                    font-size: 40px;
                    text-transform: capitalize;
                    @media only screen and (max-width: 479px){
                        font-size: 30px;
                    }
                }
                .theme-description{
                    margin-bottom: 0;
                    color: $gray-color;
                }
            }
            .data-box{
                .equity-list{
                    ul{
                        padding: 0;
                        li{
                            margin-bottom: 15px;
                            font-weight: 500;
                            align-items: center;
                            display: flex;
                            svg{
                                // background-color: $blue-color;
                                padding: 4px;
                                border-radius: 30px;
                                height: 25px;
                                width: 25px;
                                text-align: center;
                                line-height: 18px;
                                // color: #fff;
                                margin-right: 13px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .right-execution{
            img{
                border-radius: 20px;
            }
            @media only screen and (max-width: 991px) and (min-width: 768px){
                margin-top: 70px;
            }
            @media only screen and (max-width: 767px){
                margin-top: 30px;
            }
        }
    }
    .popular-products{
        background-color: #fff6f42b;
        .page-title{
            .theme-title{
                font-size: 40px;
                text-transform: capitalize;
                @media only screen and (max-width: 479px){
                    font-size: 30px;
                }
            }
            .theme-description{
                max-width: 730px;
                margin: 0 auto;
                color: $gray-color;
            }
        }
        .popular-box{
            padding: 25px 25px;
            border-radius: 10px;
            border: 1px solid $gray-border-color;
            position: relative;
            background-color: #ffffff;
            box-shadow: 0px 2px 10px $gray-border-color;
            color: $dark-gray-color;
            margin-top: 30px;
            transition: 0.7s all;
            .theme-title{
                color: $blue-color;
                font-size: 20px;
                border-bottom: 1px solid #bfbfbf47;
                margin-bottom: 15px;
                padding-bottom: 15px;
            }
            .popular-bottom{
                border-top: 1px solid $gray-border-color;
                padding-top: 15px;
                .inner-box{
                    float: left;
                    .theme-bg-color{
                        display: inline-block;
                        height: 34px;
                        width: 34px;
                        border-radius: 7px;
                        background-color: $green-color;
                        color: #fff;
                        text-align: center;
                        line-height: 34px;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
                .link-box{
                    float: right;
                    padding: 4px 0px;
                    a{
                        color: $blue-color !important;
                        transition: 0.7s all;
                        -webkit-transition: 0.7s all;
                        -moz-transition: 0.7s all;
                        -ms-transition: 0.7s all;
                        -o-transition: 0.7s all;
                    }
                }
            }
            &:hover{
                transform: translateY(-10px);
                -webkit-transform: translateY(-10px);
                -moz-transform: translateY(-10px);
                -ms-transform: translateY(-10px);
                -o-transform: translateY(-10px);
                .link-box{
                    a{
                        color: $green-color !important;
                    }
                }
            }
        }
    }
    .leading-area{
        background-image: url(../images/leading-bg.jpg);
        background-size: cover;
        background-position: center;
        padding: 90px 0px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            background-color: $blue-color;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            opacity: .82;
        }
        .get-start-box{
            position: relative;
            z-index: 11;
            text-align: center;
            .theme-title{
                color: $white-color;
                font-size: 40px;
                @media only screen and (max-width: 479px){
                    font-size: 36px;
                }
                .text-link{
                    color: $green-color;
                }
            }
            .leading-box{
                background: rgba(255, 255, 255, 0.1);
                box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
                backdrop-filter: blur(8px);
                -webkit-backdrop-filter: blur(8px);
                border: 1px solid rgba(255, 255, 255, 0.18);
                border-radius: 10px;
                margin-top: 30px;
                padding: 20px;
                min-height: 190px;
                @media only screen and (max-width: 575px){
                    min-height: auto;
                }
                .img-box{
                    margin-bottom: 14px;
                }
                .data-box{
                    .theme-title{
                        font-size: 16px;
                        margin-bottom: 2px;
                    }
                    span{
                        display: block;
                        color: $offwhite-color;
                    }
                }
            }
        }
    }
    .trader-join-area{
        .page-title{
            .theme-title{
                font-size: 40px;
                text-transform: capitalize;
                margin-bottom: 30px !important;
                @media only screen and (max-width: 479px){
                    font-size: 30px;
                }
            }
        }
        .Trader-box{
            display: flex;
            border: 1px solid #eee;
            padding: 40px;
            border-radius: 10px;
            box-shadow: 0px 0px 5px 1px #00294b17;
            position: relative;
            margin: 8px;
            @media only screen and (max-width: 575px){
                display: block;
                padding: 26px;
                margin: 15px;
            }
            .data-box{
                .theme-description{
                    color: $dark-gray-color;
                    margin: 0px 40px 24px 0px;
                }
                .bottom-testimonial{
                    padding-left: 50px;
                    position: relative;
                    .small-quote{
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-size: 27px;
                        color: $green-color;
                    }
                    .theme-title{
                        font-size: 20px;
                        margin-bottom: 4px;
                    }
                    span{
                        display: block;
                        text-transform: capitalize;
                        color: #00294bc4;
                        font-weight: 500;
                    }
                }
            }
            .img-box{
                @media only screen and (max-width: 575px){
                    margin-top: 30px;
                    text-align: center;
                }
                img{
                    max-width: 120px;
                    @media only screen and (max-width: 575px){
                        margin: 0 auto;
                    }
                }
            }
            .big-icon{
                position: absolute;
                right: 30px;
                bottom: 9px;
                font-size: 60px;
                color: $green-color;
                opacity: 0.38;
                transition: 0.7s all;
                -webkit-transition: 0.7s all;
                -moz-transition: 0.7s all;
                -ms-transition: 0.7s all;
                -o-transition: 0.7s all;
            }
        }
    }
    .mobile-app-area{
        background-color: #00294b0d;
        .chalange-main-box{
            display: table;
            width: 100%;
            @media only screen and (max-width: 767px){
                display: block;
                width: 100%;
            }
            .box-left{
                display: table-cell;
                width: 50%;
                vertical-align: middle;
                @media only screen and (max-width: 767px){
                    display: block;
                    width: 100%;
                }
                .page-title{
                    .theme-title{
                        font-size: 40px;
                        text-transform: capitalize;
                        @media only screen and (max-width: 479px){
                            font-size: 30px;
                        }
                    }
                    .theme-description{
                        margin-bottom: 0;
                        color: $gray-color;
                    }
                    .btn-box{
                        &.app-btn{
                            margin-top: 30px;
                            ul{
                                li{
                                    display: inline-block;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                    .equity-list{
                        margin-top: 25px;
                        ul{
                            li{
                                margin-bottom: 15px;
                                font-weight: 500;
                                align-items: center;
                                display: flex;
                                svg{
                                    padding: 4px;
                                    border-radius: 30px;
                                    height: 25px;
                                    width: 25px;
                                    text-align: center;
                                    line-height: 18px;
                                    margin-right: 13px;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
            .box-right{
                display: table-cell;
                width: 50%;
                vertical-align: middle;
                @media only screen and (max-width: 767px){
                    display: block;
                    width: 100%;
                    margin-top: 40px;
                }
                .app-img-box{
                    position: relative;
                    &::after{
                        content: "";
                        height: 430px;
                        width: 430px;
                        background-color: #233c520f;
                        position: absolute;
                        left: 70px;
                        top: 20px;
                        border-radius: 100%;
                        z-index: -1;
                        @media only screen and (max-width: 991px){
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .blog-area{
        .page-title{
            margin-bottom: 50px;
            .theme-title{
                font-size: 40px;
                text-transform: capitalize;
                @media only screen and (max-width: 479px){
                    font-size: 30px;
                }
            }
            .theme-description{
                max-width: 730px;
                margin: 0 auto;
                color: $gray-color;
            }
        }
        .blog-main-box{
            padding: 20px;
            background-color: $white-color;
            border: 1px solid $gray-border-color;
            border-radius: 10px;
            box-shadow: 0px 0px 10px $gray-border-color;
            margin-bottom: 30px;
            transition: 0.7s all;
            -webkit-transition: 0.7s all;
            -moz-transition: 0.7s all;
            -ms-transition: 0.7s all;
            -o-transition: 0.7s all;
            &:hover{
                transform: translateY(-10px);
                -webkit-transform: translateY(-10px);
                -moz-transform: translateY(-10px);
                -ms-transform: translateY(-10px);
                -o-transform: translateY(-10px);
                .theme-title{
                    a{
                        color: $green-color !important;
                    }
                }
            }
            .img-box{
                position: relative;
                text-align: center;
                img{
                    border-radius: 10px 10px 0px 0px;
                }
                span{
                    display: inline-block;
                    background-color: $green-color;
                    position: absolute;
                    bottom: 14px;
                    right: 14px;
                    color: $white-color;
                    padding: 5px 18px;
                    border-radius: 40px;
                    font-weight: 500;
                    font-size: 14px;
                }
            }
            .content-box{
                margin-top: 20px;
                .theme-title{
                    font-size: 20px;
                    line-height: 32px;
                    padding-left: 20px;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 4px;
                        height: 100%;
                        background-color: $green-color;
                    }
                    a{
                        color: $black-color;
                    }
                }
                .theme-description{
                    color: $gray-color;
                }
            }
            .blog-bottom{
                border-top: 1px solid #eee;
                padding-top: 15px;
                .left-box{
                    float: left;
                    .blog-imgbox{
                        span{
                            display: inline-block;
                            font-weight: 500;
                            color: $green-color;
                            img{
                                max-width: 36px;
                                border-radius: 100%;
                                margin-right: 4px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .right-box{
                    float: right;
                    span{
                        font-size: 14px;
                        color: $dark-gray-color;
                        margin: 4px 0px;
                        display: block;
                        svg{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .get-start-area{
        background-image: url(../images/get-start-bg.jpg);
        background-size: cover;
        background-position: center;
        padding: 90px 0px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            background-color: $blue-color;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            opacity: .82;
        }
        .get-start-box{
            position: relative;
            z-index: 11;
            text-align: center;
            .theme-title{
                color: $white-color;
                font-size: 40px;
                @media only screen and (max-width: 479px){
                    font-size: 36px;
                }
            }
            .theme-description{
                color: $white-color;
                font-size: 18px;
                a{
                    color: $green-color !important;
                }
            }
            .btn-box{
                margin-top: 40px;
                a{
                    &:first-child{
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    /*About Us Page SCSS*/
    .about-info-area{
        .left-about{
            text-align: center;
            @media only screen and (max-width: 991px){
                margin-bottom: 40px;
            }
            img{
                border-radius: 20px;
            }
        }
        .theme-title{
            font-size: 40px;
            text-transform: capitalize;
            @media only screen and (max-width: 479px){
                font-size: 30px;
            }
        }
        .theme-description{
            color: $gray-color;
        }
    }
    .regulation-compliance-area{
        .theme-description{
            color: $gray-color;
        }
        .content{
            .theme-description{
                color: $gray-color;
            }
            li{
                margin-bottom: 15px;
                font-weight: 500;
                align-items: center;
                display: flex;
                svg{
                    padding: 4px;
                    border-radius: 30px;
                    height: 25px;
                    width: 25px;
                    text-align: center;
                    line-height: 18px;
                    margin-right: 13px;
                    font-size: 12px;
                    color: #51B748;
                }
            }
        }
    }
    .why-choose-area{
        .page-title{
            margin-bottom: 50px;
            .theme-title{
                font-size: 40px;
                text-transform: capitalize;
                @media only screen and (max-width: 479px){
                    font-size: 30px;
                }
            }
            .theme-description{
                color: $gray-color;
            }
        }
        .choose-box{
            padding: 30px;
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 10px 6px #eeeeee21;
            margin-bottom: 30px;
            min-height: 400px;
            transition: 0.7s all;
            -webkit-transition: 0.7s all;
            -moz-transition: 0.7s all;
            -ms-transition: 0.7s all;
            -o-transition: 0.7s all;
            &:hover{
                transform: translateY(-10px);
                -webkit-transform: translateY(-10px);
                -moz-transform: translateY(-10px);
                -ms-transform: translateY(-10px);
                -o-transform: translateY(-10px);
            }
            @media only screen and (max-width: 767px){
                min-height: auto;
            }
            .top-box{
                position: relative;
                text-align: center;
                margin-bottom: 22px;
                border-bottom: 1px solid #eee;
                padding-bottom: 22px;
                svg{
                    color: $green-color;
                    font-size: 40px;
                    padding: 40px;
                    background-color: #00294b0d;
                    clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
                    -webkit-clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
                }
            }
            .theme-title{
                font-size: 20px;
                margin-bottom: 10px;
                text-transform: capitalize;
            }
            .theme-description{
                color: $dark-gray-color;
            }
        }
    }
    /*Contact Us Page SCSS*/
    .contact-top-area{
        .page-title{
            margin-bottom: 50px;
            .theme-title{
                font-size: 40px;
                text-transform: capitalize;
                @media only screen and (max-width: 479px){
                    font-size: 30px;
                }
            }
        }
        .contact-top{
            padding: 30px;
            box-shadow: 0px 0px 1px 7px #eeeeee14;
            background-color: $blue-color;
            border: 1px solid $gray-border-color;
            border-radius: 10px;
            text-align: center;
            color: #fff;
            position: relative;
            overflow: hidden;
            transition: 0.7s all;
            -webkit-transition: 0.7s all;
            -moz-transition: 0.7s all;
            -ms-transition: 0.7s all;
            -o-transition: 0.7s all;
            min-height: 230px;
            @media only screen and (max-width: 991px){
                min-height: 255px;
            }
            @media only screen and (max-width: 767px){
                margin-bottom: 20px;
                min-height: auto;
            }
            &:hover{
                .big-icon{
                    transform: scale(2);
                    -webkit-transform: scale(2);
                    -moz-transform: scale(2);
                    -ms-transform: scale(2);
                    -o-transform: scale(2);
                }
            }
            .icon-box{
                height: 60px;
                width: 60px;
                background-color: $green-color;
                margin: 0 auto 30px auto;
                border-radius: 100%;
                line-height: 70px;
                svg{
                    font-size: 30px;
                    color: $white-color;
                }
            }
            .content-box{
                .theme-title, a{
                    color: $white-color !important;
                    font-size: 18px;
                    margin-bottom: 8px;
                    font-weight: 500;
                }
                .theme-description{
                    color: #bbb;
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
            .big-icon{
                position: absolute;
                right: 13px;
                bottom: -5px;
                font-size: 60px;
                color: $green-color;
                opacity: 0.38;
                transition: 0.7s all;
                -webkit-transition: 0.7s all;
                -moz-transition: 0.7s all;
                -ms-transition: 0.7s all;
                -o-transition: 0.7s all;
            }
        }
    }
    .contact-area{
        .left-contact{
            .theme-input-box{
                margin-bottom: 24px;
                label{
                    font-weight: 500;
                    font-size: 15px;
                    margin-bottom: 8px;
                }
                .input-box{
                    position: relative;
                    input{
                        padding: 13px 15px 13px 50px;
                        background-color: #edf3f6;
                        color: #000;
                        border: 1px solid #eee;
                        font-weight: 500;
                    }
                    svg{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 20px;
                        color: $blue-color;
                    }
                }
                .theme-input{
                    resize: none;
                    padding: 13px 15px 13px 20px;
                    background-color: #edf3f6;
                    color: #000;
                    border: 1px solid #eee;
                    font-weight: 500;
                }
            }
            .btn-box{
                .theme-btn{
                    font-size: 18px;
                    letter-spacing: 0.3px;
                    padding: 10px 20px;
                    border-radius: 8px;
                    background-color: $green-color;
                    color: $white-color;
                    outline: none;
                    border: none;
                }
            }
        }
        .contact-main{
            padding: 40px 0px 40px 60px;
            @media only screen and (max-width: 767px){
                padding: 0;
            }
            .left-about{
                position: relative;
                padding: 10px 0px;
                text-align: center;
                img{
                    border-radius: 20px;
                    @media only screen and (max-width: 767px){
                        display: none;
                    }
                }
                .shape-top{
                    position: absolute;
                    left: -41px;
                    top: -36px;
                    z-index: -1;
                    @media only screen and (max-width: 991px){
                        display: none;
                    }
                }
                .shape-bottom{
                    position: absolute;
                    right: -37px;
                    bottom: -35px;
                    z-index: -1;
                    @media only screen and (max-width: 991px){
                        display: none;
                    }
                }
            }
        }
    }
    /*Products Pages SCSS*/
    .products-page-main{
        .product-page-info-section{
            .left-content{
                @media only screen and (max-width: 991px){
                    margin-bottom: 40px;
                }
                img{
                    border-radius: 20px;
                }
               }
               .right-content{
                .theme-title{
                    @media only screen and (max-width: 479px){
                        font-size: 30px;
                    }
                }
                .theme-description{
                    color: $gray-color;
                }
                .benefits-list{
                    ul{
                        li{
                            margin-bottom: 15px;
                            font-weight: 500;
                            align-items: center;
                            display: flex;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            svg{
                                padding: 4px;
                                border-radius: 30px;
                                height: 25px;
                                width: 25px;
                                text-align: center;
                                line-height: 18px;
                                margin-right: 13px;
                                font-size: 12px;
                                color: $green-color;
                            }
                        }
                    }
                    .btn{
                        background-color: $green-color;
                        color: $white-color !important;
                    }
                }
            }
            .account-types-table{
                svg{
                    color: $green-color;
                    font-size: 20px;
                }
            }
        }
        .benefits-section{
            .left-content{
                @media only screen and (max-width: 991px){
                    margin-bottom: 40px;
                }
                .theme-title{
                    @media only screen and (max-width: 479px){
                        font-size: 30px;
                    }
                }
                .theme-description{
                    color: $gray-color;
                    a{
                        color: $green-color !important;
                    }
                }
                .benefits-list{
                    ul{
                        li{
                            margin-bottom: 15px;
                            font-weight: 500;
                            align-items: center;
                            display: flex;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            svg{
                                padding: 4px;
                                border-radius: 30px;
                                height: 25px;
                                width: 25px;
                                text-align: center;
                                line-height: 18px;
                                margin-right: 13px;
                                font-size: 12px;
                                color: $green-color;
                            }
                        }
                    }
                    .btn{
                        background-color: $green-color;
                        color: $white-color !important;
                    }
                }
            }
            .right-content{
                img{
                    border-radius: 20px;
                }
            }
        }
        .advantage-section{
            .theme-title{
                @media only screen and (max-width: 479px){
                    font-size: 30px;
                }
            }
            .theme-description{
                color: $gray-color;
            }
            .advantage-list{
                .advantage-box{
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    svg{
                        padding: 4px;
                        border-radius: 30px;
                        height: 25px;
                        width: 25px;
                        text-align: center;
                        line-height: 18px;
                        margin-right: 13px;
                        font-size: 12px;
                        color: $green-color;
                    }
                    .theme-title{
                        font-size: 16px;
                        font-weight: normal;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .promotion-slider{
            .image-outer{
                position: relative;
                .content{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    text-align: center;
                    .theme-title{
                        color: $white-color;
                        @media only screen and (max-width: 575px){
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                    }
                    .btn{
                        background-color: $green-color;
                        color: $white-color;
                    }
                }
            }
        }
    }

    .tabs-container {
        .tabs-header {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          border-bottom: 1px solid #ccc;
          flex-wrap: wrap; // Allows tabs to stack on smaller screens
      
          .tab-button {
            padding: 10px 20px;
            border: none;
            background: none;
            cursor: pointer;
            font-size: 16px;
            flex: 1; // Distributes space evenly
            text-align: center;
            min-width: 100px; // Ensures minimum width for buttons
      
            &.active {
              font-weight: bold;
              color: $green-color;
              border-bottom: 2px solid $green-color;
            }
          }
        }
      
        .tabs-content {
          padding: 20px;
          border: 1px solid #ccc;
          margin-top: -1px;
          background: #f9f9f9;
        }
      }
      
      // Media Queries for responsiveness
      @media (max-width: 768px) {
        .tabs-container {
          .tabs-header {
            .tab-button {
              font-size: 14px; // Adjust font size for smaller screens
              padding: 8px 10px; // Adjust padding for smaller screens
            }
          }
        }
      }
      
      @media (max-width:625px) {
        .tabs-container {
          .tabs-header {
            flex-direction: column; // Stacks buttons vertically
            align-items: stretch; // Ensures buttons span the width
      
            .tab-button {
              padding: 10px;
              text-align: center;
              width: 100%; // Makes buttons full-width
            }
          }
        }
      }
      
      .calculator-blocks{
        margin-top: 30px;
        .box-outer{
            padding: 25px 25px;
            border-radius: 10px;
            border: 1px solid #eeeeee;
            position: relative;
            background-color: #ffffff;
            box-shadow: 0px 2px 10px #eeeeee;
            color: #5a5a5b;
            margin-top: 30px;
            min-height: 480px;
            position: relative;
            @media only screen and (max-width: 767px){
                min-height: 400px;
            }
            .title{
                color: #00294B;
                font-size: 20px;
                border-bottom: 1px solid rgba(191, 191, 191, 0.2784313725);
                margin-bottom: 15px;
                padding-bottom: 15px;
            }
            .image{
                margin-bottom: 15px;
                img{
                    width: 150px;
                }
            }
            .desc{
                margin-bottom: 15px;
            }
            .button{
                position: absolute;
                bottom: 25px;
                left: 0;
                right: 0;
                a{
                    display: flex;
                    height: 40px;
                    width: 160px;
                    border-radius: 7px;
                    background-color: #51B748;
                    color: #fff !important;
                    text-align: center;
                    line-height: 34px;
                    font-size: 16px;
                    font-weight: 500;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                }
            }
        }
      }

    .footer{
        padding: 30px 0px 40px;
        background-color: #fafafa;
        .footer-box{
            margin-top: 30px;
            .img-box{
                margin-bottom: 20px;
            }
            .social-links{
                margin-top: 30px;
                ul{
                    li{
                        display: inline-block;
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            height: 40px;
                            width: 40px;
                            border: 1px solid $gray-border-color;
                            display: block;
                            border-radius: 6px;
                            text-align: center;
                            line-height: 42px;
                            svg{
                                color: $green-color;
                            }
                        }
                    }
                }
            }
            .theme-title{
                font-size: 20px;
                border-bottom: 1px solid $gray-border-color;
                padding-bottom: 18px;
                color: $blue-color;
            }
            .links-box{
                ul{
                    li{
                        margin-bottom: 12px;
                        a{
                            color: $dark-gray-color;
                            transition: 0.7s all;
                            -webkit-transition: 0.7s all;
                            -moz-transition: 0.7s all;
                            -ms-transition: 0.7s all;
                            -o-transition: 0.7s all;
                            svg{
                                margin-right: 14px;
                            }
                            &:hover{
                                color: $green-color !important;
                            }
                        }
                    }
                }
            }
        }
        .bottom-footer{
            border-top: 1px solid #eeeeee;
            margin-top: 40px;
            padding-top: 40px;
            p{
                font-size: 14px;
                color: $gray-color;
            }
        }
        .footer-disclaimer{
            border-top: 1px solid $gray-border-color;
            margin-top: 30px;
            .theme-title{
                font-size: 24px;
                margin-bottom: 10px;
            }
            .theme-description{
                font-size: 12px;
            }
        }
    }
}